$(function() {

    //slick
    $('.p-top-slider-list').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow:1,
        centerMode:true,
        infinite: true,
        centerPadding: '20%',
        pauseOnHover:false,
        dots: true,
        dotsClass: 'slide-dots',
        responsive: [
        {
          breakpoint: 767,
          settings: {
            centerMode:false,
            slidesToShow: 1,
            centerPadding:'0%'
          }
        },
        ]
    });

    //絞り込み表示切り替え
    if (window.matchMedia( "(max-width: 767px)" ).matches) {
        $('.p-top-contents__search__wrapper').hide();

        $(document).on('click', '.p-top-contents__search__title', function() {
            $('.p-top-contents__search__wrapper').toggle();
            $('.p-top-contents__search__title').toggleClass('open');
        });
    };

    var defaultHTML = $('.p-top-contents__wrap').html();

    // HTML記述を初期状態に戻す
    $(document).on("click", '#all', function(){
        $('.p-top-contents__wrap').html(defaultHTML);
    });

    //リストの絞り込み
    var searchBox = '.p-top-contents__search__box';
    var listItem = '.p-top-contents__list__item';
    var hideClass = 'is-hide';

    $(document).on('change', searchBox + ' input', function() {
        search_filter();
    });

    $(document).on("click", "label", function(){
        $(this).toggleClass('is-active');
        $('.all-btn').removeClass('is-active');
    });

    function search_filter() {
        $(listItem).removeClass(hideClass);
        for (var i = 0; i < $(searchBox).length; i++) {
            var name = $(searchBox).eq(i).find('input').attr('name');
            var searchData = get_selected_input_items(name);
            if(searchData.length === 0) {
                continue;
            }
            for (var j = 0; j < $(listItem).length; j++) {
                var itemData = get_setting_values_in_item($(listItem).eq(j), name);
                var check = array_match_check(itemData, searchData);
                if(!check) {
                    $(listItem).eq(j).addClass(hideClass);
                }
            }
        }
    }

    function get_selected_input_items(name) {
        var searchData = [];
        $('[name=' + name + ']:checked').each(function() {
            searchData.push($(this).val());
        });
        return searchData;
    }

    function get_setting_values_in_item(target, data) {
        var itemData = target.data(data);
        if(!Array.isArray(itemData)) {
            itemData = [itemData];
        }
        return itemData;
    }

    function array_match_check(arr1, arr2) {
        var arrCheck = false;
        for (var i = 0; i < arr1.length; i++) {
            if(arr2.indexOf(arr1[i]) >= 0) {
                arrCheck = true;
                break;
            }
        }
        return arrCheck;
    }
});
